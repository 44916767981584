import { Component, HostListener } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ScannerDataService } from 'assets/services/scanner-data.service';
import { Subscription } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { PersonService } from './modules/person/person.service';
import { OvenService } from './modules/oven/oven.service';
import { Enums } from './modules/person/person.enums';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
    providers: [PersonService, OvenService]
})
export class AppComponent
{
    subscription: Subscription;
    scannedData: string = '';
    userRoles: any = [];
    /**
     * Constructor
     */
    constructor(private _router: Router,  private _scannerDataService: ScannerDataService, private _authService: AuthService, private _personService: PersonService, private _ovenService: OvenService)
    {
        this.userRoles = this._authService.getUserRoles();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.key === 'Enter') {

            console.log('enter detected');

            if(this._router.url == '/operations/schedulings/today'){
                this.setScannedIds();
            }
            else if(this._router.url == '/operations/processings'){
                this.setScannedIds();
            }
            else if(this.userRoles.includes('Administrator')){
                if(this.scannedData.includes("p-")){
                    const index = this.scannedData.indexOf("p-");
                    this.scannedData = this.scannedData.substring(index);

                    const personId = this.scannedData.substring(2);

                    console.log('received person id ',personId);

                    this._personService.findPerson(personId).subscribe({
                        next: res => {
                            if(res){
                                this._router.navigate(['/reports', personId]);
                            }
                        },
                        error: (err) => {
                            console.log('error', err);
                        }
                    });
                }
                else if(this.scannedData.includes("o-")){
                    const index = this.scannedData.indexOf("o-");
                    this.scannedData = this.scannedData.substring(index);

                    const ovenId = this.scannedData.substring(2);

                    this._ovenService.findOven(ovenId).subscribe({
                        next: (v) => {
                            if(v){
                                this._router.navigate(['/admin/ovens', ovenId]);
                            }
                        },
                        error: (e) => {
                            console.log('error', e);
                        }
                    });
                }
            }
            else { //if(this.userRoles.inclusdes('Operations'))
                if(this.scannedData.includes("p-")){
                    const index = this.scannedData.indexOf("p-");
                    this.scannedData = this.scannedData.substring(index);

                    const personId = this.scannedData.substring(2);

                    console.log('received person id ',personId);

                    this._personService.getPerson(personId).subscribe(res => {
                        if(res){
                            if(res.statusId == Enums.PersonStatus.Receving){
                                this._router.navigate(['/operations/receivings/', personId]);
                            }
                            else if(res.statusId == Enums.PersonStatus.Processing){
                                this._router.navigate(['/operations/processings/', personId]);
                            }
                            else if(res.statusId == Enums.PersonStatus.Scheduling){
                                this._router.navigate(['/operations/schedulings/planning']);
                            }
                            else if(res.statusId == Enums.PersonStatus.Delivery){
                                this._router.navigate(['/operations/deliveries/planning']);
                            }
                        }
                    });
                }
                else if(this.scannedData.includes("o-")){
                    const index = this.scannedData.indexOf("o-");
                    this.scannedData = this.scannedData.substring(index);

                    const ovenId = this.scannedData.substring(2);

                    this._ovenService.findOven(ovenId).subscribe({
                        next: (v) => {
                            if(v){
                                this._router.navigate(['/admin/ovens', ovenId]);
                            }
                        },
                        error: (e) => {
                            console.log('error', e);
                        }
                    });
                }
            }
            this.scannedData = '';
        }
        else {
            if (event.key === '-' || (event.key >= 'a' && event.key <= 'z') || (event.key >= '0' && event.key <= '9')) {
                this.scannedData += event.key;
                //console.log('scanned data captured',this.scannedData);

            }
        }
    }

    setScannedIds(){
        if(this.scannedData.includes("p-")){
            const index = this.scannedData.indexOf("p-");
            this.scannedData = this.scannedData.substring(index);
            console.log('scanned data ',this.scannedData);
            this._scannerDataService.setScannedId(this.scannedData);
        }
        else if(this.scannedData.includes("o-")){
            const index = this.scannedData.indexOf("o-");
            this.scannedData = this.scannedData.substring(index);
            console.log('scanned data ',this.scannedData);
            this._scannerDataService.setScannedId(this.scannedData);
        }
    }

    getIdFromBarcode(): string {
        let id = this.scannedData;
        if(this.scannedData.includes("p-")){
            const index = this.scannedData.indexOf("p-");
            id = this.scannedData.substring(index);
        }
        else if(this.scannedData.includes("o-")){
            const index = this.scannedData.indexOf("o-");
            id = this.scannedData.substring(index);
        }

        return id;
    }
}
