import { DateTime, DateTimeMaybeValid } from "luxon";
import { PersonStatus } from "../person-status/person-status.types";
import { CoffinType } from "../coffin-type/coffin-type.types";
import { Instruction } from "../instruction/instruction.types";
import { PersonSize } from "../person-size/person-size.types";
import { ReceivedDocumentType } from "../received-document-type/received-document-type.types";
import { Undertaker } from "../undertaker/undertaker.types";
import { PersonUrnType, UrnType } from "../urn-type/urn-type.types";
import { GeneratedDocumentType } from "../generated-document-type/generated-document-type.types";
import { Doctor } from "../doctor/doctor.types";
import { Enums } from "./person.enums";
import { FileUpload } from "../file-upload/file-upload.types";
import { Oven } from "../oven/oven.types";

export enum ActionStage {
    Receive = 1,
    Process = 2,
    Schedule = 3,
    Delivery = 4,
    Report = 5
};

export interface Person {
    id: string;
    initials: string;
    fullName: string;
    lastName: string;
    notes?: string;
    specialRequests?: string;
    bodyBagNumber?: string;
    idNumber?: string;
    invoiceNumber?: string;
    occupation?: string;
    price?: number;
    hasRemovedHazardousMaterial: boolean;
    isUrgent: boolean;
    expectedDeliveryDate?: string;
    dateReceived?: string;
    dateCremated?: DateTime;
    dateDeath?: DateTime;
    dateReturned?: DateTime;
    dateDocumentsReceived?: DateTime;
    statusId: number;
    status: PersonStatus;
    sizeId?: number;
    size?: PersonSize;
    coffinTypeId?: number;
    coffinType?: CoffinType;
    undertakerId?: number;
    undertaker?: Undertaker;
    address?: Address;
    urnTypes: PersonUrnType[];
    personInstructions: PersonInstruction[];
    receivedDocuments: ReceivedDocument[];
    generatedDocuments: GeneratedDocument[];
    scheduleId?: number;
    scheduleIndex?: number;
    deliveryId?: number;
    deliveryIndex?: number;
    deliverySignatureId: number;
    deliverySignature?: FileUpload;
    ovenId?: string;
    oven?: Oven;
    undertakerTagNumber?: string;
    registerNumber?: string;
};

export interface PersonInstruction {
    personId: string;
    instructionId: number;
    instruction: string;
    isApproved: boolean;
    dateApproved?: DateTime;
    approvedBy?: string;
}

export interface ReceivedDocument {
    personId: string;
    dateTime: DateTime;
    documentTypeId: number;
    documentType: ReceivedDocumentType;
};

export interface GeneratedDocument {
    personId: string;
    documentTypeId: number;
    documentType: GeneratedDocumentType;
    dateGenerated?: DateTime;
    isReceived: boolean;
    dateReceived?: DateTime;
    doctorId: number;
    doctor: Doctor;
};

export interface Address {
    id: number;
    houseNumber?: string;
    street?: string;
    suburb?: string;
    city?: string;
    postalCode?: string;
    country?: string;
};

export interface AddressRequest {
    houseNumber?: string;
    street?: string;
    suburb?: string;
    city?: string;
    postalCode?: string;
    country?: string;
};

export interface PersonReceiveRequest {
    id?: string;
    initials: string;
    fullName: string;
    lastName: string;
    idNumber: string;
    dateReceived?: DateTime;
    coffinTypeId?: number;
    hasRemovedHazardousMaterial: boolean;
    isUrgent: boolean;
    expectedDeliveryDate?: DateTime;
    notes?: string;
    undertakerId?: number;
    receivedDocumentTypeIds: number[];
    receivedDocuments: ReceivedDocument[];
    instructionIds: number[];
    personUrnTypes?: PersonUrnType[];
    undertakerTagNumber?: string;
    registerNumber?: string;
    sizeId?: number;
};

export interface CreatePersonReceive {
    receiveDetails: PersonReceiveRequest;
    statusId: number;
};

export interface UpdatePersonReceive extends CreatePersonReceive {
    id: string;
};

export interface PersonProcessRequest {
    dateDeath?: DateTime;
    bodyBagNumber?: string;
    price?: number;
    invoiceNumber?: string;
    address?: AddressRequest;
    occupation?: string;
    specialRequests?: string;
    //sizeId?: number;
    //personUrnTypes?: PersonUrnType[];
    generatedDocuments: GeneratedDocumentRequest[];
};

export interface UpdatePersonProcess extends UpdatePersonReceive {
    processDetails: PersonProcessRequest;
};

export interface PersonScheduleRequest {
    coffinTypeId: number;
    dateCremated: DateTime;
    ovenId: string;
};

export interface UpdatePersonSchedule {
    id: string;
    scheduleDetails: PersonScheduleRequest;
    statusId: number;
};

export interface DeliveryRequest {
    dateReturned: DateTime;
    signedImage: File;
};

export interface PersonFilter {
    pageNumber: number;
    pageSize: number;
    sortBy?: string;
    sortDirection?: 'asc' | 'desc' | '';
    dateReceivedFrom?: DateTime;
    dateReceivedTo?: DateTime;
    undertakerId?: number;
    outstandingDocuments: Enums.OutstandingDocumentType[];
    query?: string;
    actionStage: ActionStage;
};

export interface PersonReceivingFormData {
    id?: string;
    initials: string;
    fullName: string;
    lastName: string;
    idNumber: string;
    dateReceived?: DateTime;
    coffinTypeId?: number;
    hasRemovedHazardousMaterial: boolean;
    isUrgent: boolean;
    expectedDeliveryDate?: DateTime;
    notes?: string;
    undertakerId?: number;
    receivedDocumentTypeIds: number[];
    receivedDocuments: ReceivedDocument[];
    instructionIds: number[];
    personUrnTypes?: PersonUrnType[];
    undertakerTagNumber?: string;
    registerNumber?: string;
    sizeId?: number;
};

export interface PersonProcessingFormData {
    dateDeath?: DateTime;
    bodyBagNumber?: string;
    price?: number;
    invoiceNumber?: string;
    address?: AddressRequest;
    occupation?: string;
    specialRequests?: string;
    //personUrnTypes?: PersonUrnType[];
    generatedDocuments: GeneratedDocumentFormData[];
};

export interface GeneratedDocumentRequest {
    documentTypeId: number;
    doctorId?: number;
    isReceived: boolean;
    address?: AddressRequest;
    occupation?: string;
};

export interface GeneratedDocumentFormData {
    documentTypeId: number;
    doctorId?: number;
    isReceived: boolean;
};

export interface GenerateDocument {
    personId: string;
    generatedDocumentDetails: GeneratedDocumentRequest;
};

export interface PersonSchedulingFormData {
    dateCremated: DateTime;
    ovenId: string;
};

export interface PersonDeliveryFormData {
    dateReturned: DateTime;
    signature: File;
};

export interface PersonIdNumber {
    personId: string;
    idNumber: string;
}

export interface SelectablePerson extends Person {
    isSelected: boolean;
    allowToggle: boolean;
};

export interface PersonSelectedEvent {
    person: Person;
    isSelected: boolean;
}

export interface UpdatePriceAndInvoiceNumber {
    personId: string;
    price: number;
    invoiceNumber: string;
}
